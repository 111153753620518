<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Rango de fechas</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Desde*</label>
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Hasta*</label>
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
        <!--Providers-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona proveedor</h6>
              <v-select :options="providers" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="provider" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>


    <new :quotes="quotes" :providers="providers" @add="addDataSideBar" :isSidebarActive="showNewSidebar"
         @closeSidebar="showNewSidebar= false"/>

    <!--
    <update @update="updateDateSideBar" :isSidebarActive="showUpdateSidebar"
            @closeSidebar="showUpdateSidebar= false"
            :data="sidebarData"/>
            -->

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                               <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <!--Link-->
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="openLink(selected.link)">
                            <span class="flex items-center">
                              <feather-icon icon="ExternalLinkIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Ver documento (O)</span>
                            </span>
              </vs-dropdown-item>
              <!--Print-->
              <vs-dropdown-item
                  v-if="selected.id"
                  @click="openPrintDialog(selected)">
                            <span class="flex items-center">
                              <feather-icon
                                  icon="PrinterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Imprimir (I)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="client">Proveedor</vs-th>
        <vs-th sort-key="sequence">Nro. OC</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="registrationDate">Fecha de registro</vs-th>
        <vs-th sort-key="cot">Cot.</vs-th>
        <vs-th sort-key="user">Usuario</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate product-name">{{ tr.provider.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <p>
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total)
              }}
            </p>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <vs-chip @click.native="openLink(tr.quote.link)" color="dark" class="product-order-status">
              {{ tr.quote.code }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.user.displayName }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon @click="openLink(tr.link)" title="Link" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>

            <feather-icon @click="openPrintDialog(tr)" title="Imprimir"
                          icon="PrinterIcon"
                          class="mr-3"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"/>

            <feather-icon v-if="tr.status === 'hold'" @click="openCancelDialog(tr)" title="Anular"
                          icon="XIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>


            <feather-icon v-if="tr.status === 'hold'" @click="regeneratePDF(tr)" title="Reenviar"
                          icon="UploadIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'

import { auth, db, FieldValue, storage, Timestamp } from '@/firebase/firebaseConfig'
import New from '@/views/purchases/New'
//import Update from '@/views/purchases/Update'
import { es } from 'vuejs-datepicker/dist/locale'
import openLink from '@/mixins/link'
import axios from 'axios'

const _ = require('lodash')

export default {
  name: 'PurchasesList',
  components: {
    vSelect,
    New,
    //Update,
    Datepicker
  },
  data () {
    return {
      selected: {},
      selectedPurchase: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      providers: [],
      quotes: [],
      initProgress: false,

      // Data Sidebar
      showUpdateSidebar: false,
      showNewSidebar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,
      // Filter
      startDate: null,
      endDate: null,
      es: es,
      provider: {},
      evt: null
    }
  },
  mixins: [openLink],
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadPurchases()
      await this.loadProviders()
      await this.loadQuotes()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true

    // Listen keyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const _ = require('lodash')
        const list = _.cloneDeep(this.list)
        list.forEach((o) => {
          o.provider = o.provider.businessName
          o.currency = o.currency.value
          o.createdAt = this.$options.filters.date(o.createdAt, true)
          o.user = o.user.displayName
          o.state = this.getStateText(o.status)
          o.quote = o.quote.code
        })
        const headerVal = ['provider', 'code', 'currency', 'total', 'createdAt', 'user', 'state', 'quote']
        const headerTitle = ['Proveedor', 'Nro. Oc', 'Moneda', 'Total', 'Fecha de registro', 'Usuario', 'Estado', 'Cotización']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDateSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'success') return 'dark'
      if (status === 'rejected') return 'danger'
      return 'success'
    },
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'invoiced') return 'Facturada'
      if (value === 'rejected') return 'Anulada'
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loadPurchases () {
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('purchases')
            .orderBy('createdAt', 'desc')
            .limit(400).get()
      } else {
        querySnap = await db.collection('purchases')
            .where('user.uid', '==', auth.currentUser.uid)
            .orderBy('createdAt', 'desc')
            .limit(400).get()
      }
      querySnap.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate()
        }
        this.list.push(obj)
      })
    },
    /**
     * Get providers of firestore
     * @returns {Promise<void>}
     */
    async loadProviders () {
      this.clients = []
      const querySnap = await db.collection('providers').where('state', '==', true).orderBy('businessName', 'asc').get()
      querySnap.forEach((doc) => {
        let client = {
          id: doc.id,
          document: doc.data().document,
          typeDocument: doc.data().typeDocument,
          businessName: doc.data().businessName,
          label: doc.data().businessName + ' - ' + doc.data().document,
          address: doc.data().address,
          department: doc.data().department,
          province: doc.data().province,
          district: doc.data().district,
          ubigeo: doc.data().ubigeo
        }
        this.providers.push(client)
      })
    },
    /**
     * Get quotes of firestore
     * @returns {Promise<void>}
     */
    async loadQuotes () {
      this.quotes = []
      let querySnap = {}
      if (this.$acl.check('admin')) {
        querySnap = await db.collection('quotes')
            .where('state', '==', true)
            .where('status', '!=', 'invoiced')
            .orderBy('status', 'asc')
            .orderBy('createdAt', 'desc').get()
      } else {
        querySnap = await db.collection('quotes')
            .where('user.uid', '==', auth.currentUser.uid)
            .where('state', '==', true)
            .where('status', '!=', 'invoiced')
            .orderBy('status', 'asc')
            .orderBy('createdAt', 'desc').get()
      }
      querySnap.forEach((doc) => {
        let quote = {
          id: doc.id,
          code: doc.data().code,
          client: doc.data().client,
          contact: doc.data().contact,
          currency: doc.data().currency,
          total: doc.data().total,
          igv: doc.data().igv,
          subTotal: doc.data().subTotal,
          products: doc.data().products,
          createdAt: doc.data().createdAt.toDate(),
          roundingType: doc.data().roundingType,
          status: doc.data().status,
          link: doc.data().link
        }
        this.quotes.push(quote)
      })
    },
    /**
     * Send to Eprint
     * @param tr
     */
    openPrintDialog (tr) {
      this.selectedPurchase = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas imprimir remotamente la orden de compra "${tr.code}"?`,
        accept: this.acceptDialogPrint,
        cancel: '',
        parameters: [tr],
        acceptText: 'Imprimir',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Eprint
     * @returns {Promise<void>}
     */
    async acceptDialogPrint () {
      try {
        this.initProgress = true
        await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/print-sendToPrint', {
          url: this.selectedPurchase.link
        }, {
          headers: {
            Authorization: 'Bearer bd14b377-92f6-4741-8303-1f372131897c'
          }
        })

        this.selectedGuide = {}
        this.$vs.notify({
          color: 'success',
          title: 'Orden de compra',
          text: 'Orden de compra enviada a impresión.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: e.response.data.errors
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Cancel purchase orde
     * @param tr
     */
    openCancelDialog (tr) {
      this.selectedPurchase = _.cloneDeep(tr)
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas anular la orden de compra "${tr.code}"?`,
        accept: this.acceptCancel,
        cancel: '',
        parameters: [tr],
        acceptText: 'Anular',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Eprint
     * @returns {Promise<void>}
     */
    async acceptCancel () {
      try {
        this.initProgress = true

        // Get and validate quote status
        const quoteDoc = await db.collection('quotes').doc(this.selectedPurchase.quote.id).get()
        if (quoteDoc.data().status !== 'invoiced') {

          // Change Status
          await db.collection('purchases').doc(this.selectedPurchase.id).update({
            status: 'rejected'
          })

          // Delete of quote movements
          const querySnap = await db.collection('quotes')
              .doc(this.selectedPurchase.quote.id)
              .collection('quotesMovements')
              .where('document', '==', this.selectedPurchase.code).get()
          let movementRef = null
          querySnap.forEach((m) => {
            movementRef = m.ref
          })
          await movementRef.delete()

          /* Update products quantity*/

          // Delete products to quote
          const quoteProducts = [...quoteDoc.data().products]
          quoteProducts.forEach((qp, index) => {
            const product = this.selectedPurchase.products.find((p) => p.id === qp.id)
            quoteProducts[index].purchaseOrder -= product.quantity
          })
          // Update Quote status and products
          await db.collection('quotes').doc(this.selectedPurchase.quote.id).update({
            products: quoteProducts,
            updatedAt: FieldValue.serverTimestamp()
          })
          /* End */

          this.selectedPurchase.status = 'rejected'
          this.updateDateSideBar(this.selectedPurchase)
          this.selectedPurchase = {}
          this.$vs.notify({
            color: 'success',
            title: 'Orden de compra',
            text: 'Orden de compra anulada correctamente.'
          })
        } else {
          this.$vs.notify({
            color: 'warning',
            title: 'Orden de compra',
            text: 'Cotización se encuentra facturada, no se puede realizar anulación.',
            time: 8000
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.provider = null
      await this.loadPurchases()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end date
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}

          if (this.provider.id) {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('purchases')
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('provider.id', '==', this.provider.id).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('purchases')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes)
                  .where('provider.id', '==', this.provider.id).orderBy('createdAt', 'desc').get()
            }
          } else {
            if (this.$acl.check('admin')) {
              querySnapshot = await db.collection('purchases')
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            } else {
              querySnapshot = await db.collection('purchases')
                  .where('user.uid', '==', auth.currentUser.uid)
                  .where('createdAt', '>=', starTimes)
                  .where('createdAt', '<=', endTimes).orderBy('createdAt', 'desc').get()
            }
          }

          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate()
            }
            this.list.push(obj)
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected.id && !this.showDetailSidebar && !this.showUpdateSidebar && !this.activePrompt && !this.showNewSidebar) {
        if (ev.keyCode === 79) { // Open Link (O)
          if (this.selected.id) {
            this.openLink(this.selected.link)
          }
        }
        else if (ev.keyCode === 65) { // Cancel (A)
          if (this.selected.id && this.selected.status === 'hold') {
            this.openCancelDialog(this.selected)
          }
        }
        else if (ev.keyCode === 73) { // Print (I)
          this.openPrintDialog(this.selected)
        }
      }
    },
    /**
     * Resend pdf
     * @param purchase
     * @returns {Promise<void>}
     */
    async regeneratePDF (purchase) {
      try {
        this.initProgress = true
        const responsePdf = await this.generatePDF(purchase)
        setTimeout(async () => {
          const link = await this.getPdfUrl(responsePdf.id, purchase.id)
          purchase.link = link
          await db.collection('purchases').doc(purchase.id).update({
            pdfId: responsePdf.id,
            link
          })
          window.open(link, '_blank')
        }, 7000)
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Generate PDF
     */
    async generatePDF (purchase) {
      const products = []
      purchase.products.forEach((p) => {
        const obj = {
          description: p.description.substr(0, 62),
          numberPart: p.numberPart,
          quantity: p.quantity
        }

        obj.price = parseFloat((p.purchasePrice).toFixed(purchase.roundingType))
        obj.total = parseFloat((p.quantity * p.purchasePrice).toFixed(purchase.roundingType))

        obj.price = purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: purchase.currency.code,
          minimumFractionDigits: purchase.roundingType,
          maximumFractionDigits: purchase.roundingType,
        }).format(obj.price)

        obj.total = purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
          currency: purchase.currency.code,
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(obj.total)

        products.push(obj)
      })
      const response = await this.$http.post('https://api.pdfmonkey.io/api/v1/documents', {
        document: {
          document_template_id: 'BAE0681E-DB1C-4779-ACC7-646A52D85243',
          payload: {
            'code': purchase.code,
            'createdAt': `Lima ${new Date().getDate()} de ${this.getMonth(new Date().getMonth())} del ${new Date().getFullYear()}`,
            'providerName': purchase.provider.businessName,
            'providerDocument': purchase.provider.document,
            'providerAddress': purchase.address.address + ', ' + purchase.address.district + ', ' + purchase.address.province + ', ' + purchase.address.department,
            'userName': auth.currentUser.displayName,
            'userPhone': auth.currentUser.phoneNumber,
            'userEmail': auth.currentUser.email,
            'subTotal': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: purchase.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.subTotal),
            'igv': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: purchase.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.igv),
            'total': purchase.currency.symbol + ' ' + Intl.NumberFormat('en-IN', {
              currency: purchase.currency.code,
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(purchase.total),
            products
          },
          status: 'pending'
        }
      }, {
        headers: {
          'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
        }
      })
      return response.data.document
    },
    /**
     * Get URL of monkey
     */
    async getPdfUrl (id, docId) {
      let response = null
      try {
        response = await this.$http.get(`https://api.pdfmonkey.io/api/v1/documents/${id}`, {
          headers: {
            'Authorization': 'Bearer iVtju3Mvy6c2gzhGWerf'
          }
        })
        // Save in firebase storage
        const timestamp = new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate() + '-' + new Date().getHours() + '-' + new Date().getMinutes() + '-' + new Date().getSeconds()
        const docNameFinal = docId.toUpperCase() + '-' + timestamp + '.pdf'
        const responsePdf = await window.fetch(response.data.document.download_url)
        const storageRef = storage.ref(`purchases/${docNameFinal}`)
        const blob = await responsePdf.blob()
        const snapshot = await storageRef.put(blob)
        const url = await snapshot.ref.getDownloadURL()
        return url
      } catch (e) {
        return response.data.document.download_url
      }
    },
    /*+
    Get month
     */
    getMonth (month) {
      switch (month) {
        case 0:
          return 'Enero'
        case 1:
          return 'Febrero'
        case 2:
          return 'Marzo'
        case 3:
          return 'Abril'
        case 4:
          return 'Mayo'
        case 5:
          return 'Junio'
        case 6:
          return 'Julio'
        case 7:
          return 'Agosto'
        case 8:
          return 'Septiembre'
        case 9:
          return 'Octubre'
        case 10:
          return 'Noviembre'
        case 11:
          return 'Diciembre'
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .product-name {
    max-width: 23rem;
  }
}
</style>
